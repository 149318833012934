import React, {ReactNode, useEffect, useState} from 'react';
import {AddressDropdown} from './AddressDropdown/AddressDropdown';
import {useBillingData} from '../CheckoutSteps/Steps/PaymentStep/WithBillingData/WithBillingData';
import {MemberDetailsState} from '../../../types/app.types';
import {MemberContactAndAddressSummary} from '../ContactAndAddressSummary/MemberContactAndAddressSummary';
import {classes} from './MemberDetails.st.css';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {useControllerProps} from '../Widget/ControllerContext';
import {isBillingInfoValid} from '../Form/FormValidations';
import {AddressNotValidError} from '../AddressNotValidError/AddressNotValidError';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import {useMemberDetailsData} from './WithMemberDetailsData';

export enum MemberDetailsDataHook {
  root = 'MemberDetails.root',
  edit = 'MemberDetails.edit',
  new = 'MemberDetails.new',
  changeButton = 'MemberDetails.changeButton',
  editButton = 'MemberDetails.editButton',
}

export const MemberDetails = ({Form}: {Form: () => ReactNode}) => {
  const {
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  const {
    wfConfig: {formsById},
  } = useControllerProps() as any;
  const {cashierMandatoryFields} = useBillingData();
  const {getMemberAddressBySelectedServiceId, selectedAddressesServiceId, memberDetailsState, setMemberDetailsState} =
    useMemberDetailsData();
  const [isDataValid, setIsDataValid] = useState(true);

  useEffect(
    () => {
      const validateData = async (billingInfo: AddressWithContactModel) => {
        const isValid = await isBillingInfoValid({
          formsById,
          billingInfo,
          checkoutSettings,
          cashierMandatoryFields,
        });

        setIsDataValid(isValid);
      };

      const billingInfo = getMemberAddressBySelectedServiceId();
      billingInfo && void validateData(billingInfo);
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [
      cashierMandatoryFields,
      checkoutSettings,
      formsById,
      selectedAddressesServiceId,
    ]
  );

  useEffect(() => {
    if (memberDetailsState === MemberDetailsState.COLLAPSED && !isDataValid) {
      setMemberDetailsState(MemberDetailsState.OPEN);
    }
  }, [isDataValid]);

  return (
    <div>
      <div data-hook={MemberDetailsDataHook.root} className={classes.root}>
        {memberDetailsState !== MemberDetailsState.COLLAPSED && <AddressDropdown />}
        {selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID && memberDetailsState !== MemberDetailsState.EDIT && (
          <>
            <MemberContactAndAddressSummary hideEditButton={!isDataValid} />
            {!isDataValid && <AddressNotValidError />}
          </>
        )}
        {memberDetailsState === MemberDetailsState.EDIT && <div data-hook={MemberDetailsDataHook.edit}>{Form()}</div>}
        {selectedAddressesServiceId === ADD_NEW_ADDRESS_ID && (
          <div data-hook={MemberDetailsDataHook.new}>NEW (TEMPORARY)</div>
        )}
      </div>
    </div>
  );
};
