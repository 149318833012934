import React, {useEffect, useState} from 'react';
import {classes} from './MamberContactAndAddressSummary.st.css';
import {ContactAndAddressSummary} from './ContactAndAddressSummary';
import {useBillingData} from '../CheckoutSteps/Steps/PaymentStep/WithBillingData/WithBillingData';
import {MemberDetailsState} from '../../../types/app.types';
import {TextButton} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {MemberDetailsDataHook} from '../MemberDetails/MemberDetails';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import {useMemberDetailsData} from '../MemberDetails/WithMemberDetailsData';

export const MemberContactAndAddressSummary = ({hideEditButton}: {hideEditButton?: boolean}) => {
  const localeKeys = useLocaleKeys();

  const [billingInfo, setBillingInfo] = useState<AddressWithContactModel | undefined>(undefined);

  const {selectedAddressesServiceId, getMemberAddressBySelectedServiceId, memberDetailsState} = useMemberDetailsData();
  const {setEditMemberAddress} = useBillingData();

  useEffect(
    () => {
      setBillingInfo(getMemberAddressBySelectedServiceId());
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ [selectedAddressesServiceId]
  );

  const shouldShowEditButton = memberDetailsState === MemberDetailsState.OPEN && !hideEditButton;

  return (
    <div className={classes.container}>
      <ContactAndAddressSummary contact={billingInfo?.contact} address={billingInfo?.address} />
      {shouldShowEditButton && (
        <TextButton
          key={'textButton'}
          data-hook={MemberDetailsDataHook.editButton}
          className={classes.editTitle}
          onClick={setEditMemberAddress}>
          {localeKeys.checkout.edit()}
        </TextButton>
      )}
    </div>
  );
};
