import {MemberDetailsState} from '../../../types/app.types';
import {AddressWithContactModel} from '../../../domain/models/AddressWithContact.model';
import React, {ComponentType, createContext, useCallback, useContext, useState} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {CheckoutModel} from '../../../domain/models/Checkout.model';

export type MemberDetailsDataContextType = {
  selectedAddressesServiceId?: string;
  setSelectedAddressesServiceId: (id: string) => void;
  memberDetailsState: MemberDetailsState;
  setMemberDetailsState: (state: MemberDetailsState) => void;
  resetMemberDetailsState: () => void;
  getMemberAddressBySelectedServiceId: () => AddressWithContactModel | undefined;
};

export const MemberDetailsDataContext = createContext({} as MemberDetailsDataContextType);

export function withMemberDetailsData<T extends object>(
  Component: ComponentType<T>,
  getAddressAndContactFromCheckout: (checkout: CheckoutModel) => AddressWithContactModel | undefined
) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      memberStore: {addressesInfo, isAddressesAppInstalled, isMember, defaultAddressId},
    } = useControllerProps();

    const [memberDetailsState, setMemberDetailsState] = useState(MemberDetailsState.COLLAPSED);

    const getInitialSelectedAddressesServiceId = (): string | undefined => {
      if (!isMember || !isAddressesAppInstalled) {
        return undefined;
      }

      const addressAndContact = getAddressAndContactFromCheckout(checkout);

      if (addressAndContact?.addressesServiceId) {
        return addressAndContact?.addressesServiceId;
      }

      setMemberDetailsState(MemberDetailsState.OPEN);

      if (!isAddressFullEnough(addressAndContact) && addressesInfo.addresses?.length) {
        return defaultAddressId;
      }

      return ADD_NEW_ADDRESS_ID;
    };

    const [selectedAddressesServiceId, setSelectedAddressesServiceId] = useState(getInitialSelectedAddressesServiceId);

    const getMemberAddressBySelectedServiceId = useCallback(() => {
      return addressesInfo.addresses?.find((address) => address.addressesServiceId === selectedAddressesServiceId);
    }, [selectedAddressesServiceId, addressesInfo]);

    const resetMemberDetailsState = () => {
      setMemberDetailsState(MemberDetailsState.COLLAPSED);
      setSelectedAddressesServiceId(getInitialSelectedAddressesServiceId());
    };

    return (
      <MemberDetailsDataContext.Provider
        value={{
          selectedAddressesServiceId,
          setSelectedAddressesServiceId,
          getMemberAddressBySelectedServiceId,
          memberDetailsState,
          setMemberDetailsState,
          resetMemberDetailsState,
        }}>
        <Component {...props} />
      </MemberDetailsDataContext.Provider>
    );
  };
}

export function useMemberDetailsData() {
  const {
    selectedAddressesServiceId,
    setSelectedAddressesServiceId,
    memberDetailsState,
    setMemberDetailsState,
    resetMemberDetailsState,
    getMemberAddressBySelectedServiceId,
  } = useContext(MemberDetailsDataContext);

  return {
    selectedAddressesServiceId,
    setSelectedAddressesServiceId,
    getMemberAddressBySelectedServiceId,
    memberDetailsState,
    setMemberDetailsState,
    resetMemberDetailsState,
  };
}

function isAddressFullEnough(addressAndContact?: AddressWithContactModel) {
  return Boolean(
    addressAndContact?.address?.addressLine ||
      (addressAndContact?.address?.city && addressAndContact?.address?.postalCode) ||
      addressAndContact?.address?.streetAddress?.name
  );
}
